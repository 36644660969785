<script setup lang="ts"></script>

<template>
  <div class="h-32 flex justify-center items-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="74"
      viewBox="0 0 332 512"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0,512) scale(0.100000,-0.100000)"
        fill="#2F2F2F"
        stroke="none"
      >
        <path
          d="M1179 5102 c-59 -20 -129 -64 -165 -103 -52 -58 -498 -724 -519 -776
-38 -96 -45 -187 -45 -628 l0 -413 -138 -116 c-185 -155 -249 -242 -292 -397
-26 -97 -26 -197 4 -569 24 -293 24 -294 51 -317 33 -29 64 -29 99 -2 33 26
33 53 6 384 -26 319 -26 397 2 481 46 139 50 143 942 889 879 734 867 725 958
711 155 -25 248 -190 180 -321 -27 -54 -40 -65 -481 -433 -282 -237 -403 -331
-457 -359 -91 -47 -500 -400 -510 -440 -14 -55 43 -113 95 -97 16 5 122 90
236 189 114 99 221 189 238 200 41 26 123 26 164 0 17 -11 175 -143 352 -294
227 -192 327 -284 341 -311 45 -89 34 -180 -31 -254 -48 -54 -91 -75 -116 -55
-9 8 -112 94 -227 192 -116 99 -227 187 -246 197 -94 48 -226 47 -316 -4 -67
-37 -483 -405 -490 -432 -15 -58 45 -116 100 -98 13 5 118 90 231 189 114 99
220 188 236 199 37 23 114 25 156 4 58 -29 677 -564 700 -605 48 -85 39 -179
-24 -253 -70 -81 -182 -107 -269 -62 -21 12 -129 98 -238 191 -110 94 -208
174 -219 177 -35 11 -62 -5 -136 -80 -66 -66 -75 -80 -75 -112 0 -78 86 -100
153 -39 21 19 41 35 44 35 3 0 79 -63 168 -140 184 -156 248 -196 338 -208
136 -19 256 23 346 120 73 79 98 146 99 264 1 85 -1 95 -37 166 -25 51 -54 91
-87 121 l-49 44 63 64 c84 86 109 150 110 275 1 87 -1 95 -39 170 -38 74 -49
87 -195 211 -85 73 -156 139 -158 145 -1 7 33 194 76 415 l79 402 34 28 c89
73 158 147 182 196 31 63 49 162 41 222 l-6 42 53 23 c65 28 157 37 199 20 42
-17 88 -73 96 -116 4 -21 -10 -238 -31 -493 -20 -250 -34 -476 -31 -501 4 -26
43 -122 95 -230 155 -322 185 -436 186 -690 0 -235 -28 -353 -142 -607 -64
-141 -68 -176 -27 -211 31 -27 77 -24 105 6 22 23 124 255 160 364 51 156 64
245 64 443 0 283 -36 423 -193 750 l-95 200 37 450 c20 247 36 471 36 497 0
106 -75 225 -174 275 -40 21 -70 27 -136 31 -76 4 -96 1 -179 -27 l-93 -31
-54 49 c-115 106 -292 128 -434 56 -25 -13 -180 -136 -345 -274 l-300 -252 -3
51 c-3 48 0 55 67 152 78 111 88 151 51 188 -26 26 -80 30 -108 8 -20 -17
-143 -199 -158 -235 -10 -22 -14 -76 -14 -167 l0 -135 -227 -190 -228 -191 -3
343 c-3 366 3 438 42 516 23 45 451 677 484 715 28 31 113 66 163 66 61 0 143
-42 179 -92 39 -53 56 -130 41 -184 -6 -23 -36 -79 -66 -125 -74 -114 -74
-170 1 -193 37 -11 70 10 118 78 68 95 95 153 108 229 28 169 -61 336 -220
410 -85 40 -197 47 -283 19z m758 -1926 l-52 -259 -107 91 c-58 50 -120 100
-137 110 l-31 19 187 156 c104 86 189 153 190 149 1 -4 -22 -123 -50 -266z"
        />
        <path
          d="M2290 5100 c-19 -19 -20 -33 -20 -210 0 -189 0 -191 25 -215 15 -16
36 -25 55 -25 19 0 40 9 55 25 25 24 25 26 25 208 0 171 -1 186 -21 211 -26
33 -88 37 -119 6z"
        />
        <path
          d="M2811 4983 c-12 -10 -57 -83 -101 -162 -86 -155 -98 -202 -62 -234
25 -23 94 -22 114 1 34 40 178 312 178 336 0 61 -82 97 -129 59z"
        />
        <path
          d="M3070 4553 c-74 -47 -143 -92 -152 -101 -25 -21 -24 -86 2 -112 37
-37 73 -26 224 66 78 48 149 95 159 106 23 27 22 83 -3 108 -39 39 -82 27
-230 -67z"
        />
        <path
          d="M94 1575 c-23 -23 -25 -31 -19 -78 20 -157 87 -337 180 -482 20 -30
112 -151 205 -267 l170 -213 0 -243 0 -243 25 -24 c32 -33 78 -33 110 0 l25
24 0 268 c0 238 -2 271 -18 295 -9 15 -96 126 -192 248 -96 121 -196 257 -222
301 -51 88 -99 223 -119 329 -7 38 -20 75 -32 88 -29 31 -80 30 -113 -3z"
        />
        <path
          d="M2709 1140 c-9 -5 -64 -115 -123 -245 l-106 -234 0 -304 c0 -293 1
-305 21 -331 26 -33 88 -37 119 -6 19 19 20 33 20 310 l0 290 95 212 c57 127
95 224 95 244 0 55 -72 93 -121 64z"
        />
      </g>
    </svg>
  </div>
</template>

<style scoped></style>
